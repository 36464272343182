import axios from "axios";
import store from "./store";
import { checkToken } from "../services/authorization.service";

const apiUrl = "https://dev.core.tastamat.com/platform/v1/rest";

const axiosInterceptorsConfig = () => {
  axios.interceptors.request.use(
    async request => {
      request.url = apiUrl + request.url;
      store.dispatch({ type: "LOADING", payload: true });
      if (request.url.includes('/a/')) {
        const token = await checkToken();
        if (token) {
          request.headers.Authorization = `Bearer ${token}`
        } else {
          return Promise.reject('sessionExpired');
        }
      }

      return request;
    },
    error => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    response => {
      store.dispatch({ type: "LOADING", payload: false });
      return response;
    },
    error => {
      store.dispatch({ type: "LOADING", payload: false });
      return Promise.reject(error);
    }
  );
};

export default axiosInterceptorsConfig;
